import axios from 'axios'
import api from '../../api'

const state = {
	stores: [],
	currentPage: 1,
	itemsPerPage: 10,
	totalItems: 0,
}

const getters = {
	paginatedStores: (state) => {
		const startIdx = (state.currentPage - 1) * state.itemsPerPage
		return state.stores.slice(startIdx, startIdx + state.itemsPerPage)
	},
	totalPages: (state) => Math.ceil(state.totalItems / state.itemsPerPage),
}

const mutations = {
	SET_ORDERS(state, orders) {
		state.orders = orders
	},
	CORRENT_ORDER(state, order) {
		state.orders = order
	},
	SET_TOTAL_ITEMS(state, totalItems) {
		state.totalItems = totalItems
	},
	SET_CURRENT_PAGE(state, page) {
		state.currentPage = page
	},
	SET_CURRENT_HEADERS(state, store) {
		state.selectedStore = store
		state.headers = {
			'X-Manager-Token': store.x_manager_token,
			Authorization: `Bearer ${store.bearer_token}`,
		}
	},
}

const actions = {
	FetchZidOrders({ commit, state }, payload) {
		if (!payload.store) return new Error('Token is required for this store!')
		commit('SET_CURRENT_HEADERS', payload.store)
		return new Promise((resolve, reject) => {
			if (!state.headers) {
				return reject(new Error('Token is required for this store!'))
			}

			let url = `https://api.zid.sa/v1/managers/store/orders`
			if (payload.params) {
				url = `${url}?${payload.params}`
			}

			axios
				.get(url, {
					headers: { ...state.headers },
				})
				.then((res) => {
					const stores = res.data
					commit('SET_ORDERS', stores)
					resolve(stores)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
	SingleZidOrder({ commit, state }, orderId) {
		return new Promise((resolve, reject) => {
			if (!state.selectedStore)
				return reject(
					new Error(
						'Please select a store before serching Zid order by ID'
					)
				)
			const url = `https://api.zid.sa/v1/managers/store/orders/${orderId}/view`

			axios
				.get(url, {
					headers: { ...state.headers },
				})
				.then((res) => {
					const order = res.data
					commit('CORRENT_ORDER', order)
					resolve(order)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
	async GetSingleOrderByStapo(_, orderNumber) {
		return new Promise((resolve, reject) => {
			api.get(`/api/order/${orderNumber}`)
				.then((res) => {
					const order = res.data
					resolve(order)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
	SyncWithWaybill(_, payload) {
		return new Promise((resolve, reject) => {
			const url = `https://api.zid.sa/v1/managers/store/orders/${payload.id}/change-order-status`
			axios
				.post(url, payload.data, {
					headers: { ...state.headers },
				})
				.then((res) => {
					resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
	SyncWithStapo(_, payload) {
		return new Promise((resolve, reject) => {
			api.post('/api/zid/sync/platform-order', payload.order)
				.then((res) => {
					console.log('🚀 ~ .then ~ res:', res)
					resolve(res.data)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
}

export default {
	state,
	getters,
	mutations,
	actions,
}
